/* eslint-disable no-undef */
'use strict';

function cleverTapEvent(eventType, eventPayload) {
    // NOTE: disparar eventos da clevertap
    // clevertap.event.push(eventType, eventPayload);
}

function getSessionLoginStatus() {
    return sessionStorage.getItem('loginChecked') === 'true';
}

function getFormData() {
    return {
        inputDeparture: $('#inputDeparture').val(),
        inputBack: $('#inputBack').val(),
        origin: $('.cities__input.cities__origin').val(),
        originId: $('#origin').val(),
        destination: $('.cities__input.cities__destination').val(),
        destinationId: $('#destination').val()
    };
}

function getCustomerData() {
    var customerData = $('.search-tickets-btn.searchBtn-mobile').data('customer');

    if (customerData) {
        return {
            email: customerData.email,
            nome: customerData.firstName,
            customerNo: customerData.customerNo,
            doc: customerData.doc,
            telefone: customerData.phone
        };

    }else{
        return null;
    }
}

function formatEventData({originId, origin, destinationId, destination, inputDeparture, inputBack}) {
    return {
        originData: `${originId} - ${origin}`,
        destinationData: `${destinationId} - ${destination}`,
        dateData: `${inputDeparture} - ${inputBack}`
    };
}

function pushClevertapEvent(formData, customerData) {
    const {originData, destinationData, dateData} = formatEventData(formData);

    let eventPayload = {
        "origem": originData,
        "destino": destinationData,
        "data": dateData
    };

    if (customerData) {
        eventPayload = {
            ...eventPayload,
            ...customerData
        };
    }
    cleverTapEvent("Busca Realizada", eventPayload);
}

function formatPhoneNumber(addresses, profile) {
    return addresses.com_telephoneddd ? addresses.com_telephoneddd + profile.phoneHome : profile.phoneHome;
}

function getCoupon(discounts) {
    if (discounts.length === 0) {
        return '';
    }
    return discounts.length > 1
        ? discounts.map(discount => discount.name).join(' - ')
        : discounts[0].name;
}

function formatPassengersData(passengerType, passengerData, eventPayload) {
    passengerData.forEach((passenger, index) => {
        let count = index + 1;
        eventPayload[`rota_${passengerType}_${count}`] = passenger.route;
        eventPayload[`poltrona_${passengerType}_${count}`] = passenger.seat;
        eventPayload[`nome_passageiro_${passengerType}_${count}`] = passenger.fullName;
    });
    return passengerData.length;
}

module.exports = {
    searchForm: function () {
        $(document).on('clevertapSearch', function () {
            if (isWebView() && getSessionLoginStatus()) {
                const formData = getFormData();
                const customerData = getCustomerData();

                pushClevertapEvent(formData, customerData);
            }
        });
    },

    checkoutForm: function () {
        $(document).on('clevertapCheckout', function(event, paymentType, customer, tripsPassengers, totals) {

            if (isWebView()) {
                const telefone = formatPhoneNumber(customer.addresses, customer.profile);
                const cupom = getCoupon(totals.discounts);

                let eventPayload = {
                    "email": customer.profile.email,
                    "nome": customer.profile.fullName,
                    "customerNo": customer.customerNumber,
                    "doc": customer.profile.CPF,
                    "telefone": telefone,
                    "valor_compra": totals.totalPrice.formatted,
                    "cupom": cupom,
                    "seguro": totals.isInsuranceActive,
                    "forma_pagamento": paymentType
                };

                const qntItensPartida = formatPassengersData('partida', tripsPassengers.departurePassengersData, eventPayload);
                const qntItensVolta = formatPassengersData('volta', tripsPassengers.returnPassengersData, eventPayload);

                eventPayload.qnt_itens_partida = qntItensPartida;
                eventPayload.qnt_itens_volta = qntItensVolta;

                cleverTapEvent("Checkout Finalizado", eventPayload);
            }
        });
    }
};
